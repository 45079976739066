angular.module('app')
    .controller('bookingProgressController',
        ["$scope", "$window", "$uibModal", "bookingDecoratorService", "bookingService", "Notification", "$log", function ($scope, $window, $uibModal, bookingDecoratorService, bookingService, Notification, $log) {

            var idParams;

            (function init() {

                $log.info('In booking progress controller init...');

                idParams = {id: $scope.booking.bookingReference};

                // Old booking decorator.  Might still be useful to shortcut some template logic
                bookingDecoratorService.decorate($scope.booking);

                // Bookings are now expired on the server
                // if (!$scope.trip.isAccepted && $scope.trip.isActive && moment().isAfter($scope.trip.expiryTime)) {
                //     cancel();  // booking has expired so cancel it immediately.
                // } else {
                //     $scope.$broadcast('timer-start');
                //     $scope.$on('timer-stopped', function () {
                //         if (!$scope.trip.isAccepted) {
                //             cancel();
                //         }
                //     });
                // }

                $scope.$on('refreshed', function () {
                    $scope.working = false;
                });

                $scope.working = false;

                $scope.getDriverEta = getDriverEta;

                $scope.cancel = cancel;
                $scope.confirmCancelBooking = confirmCancel;

                $scope.disputeCollection = disputeCollection;
                $scope.confirmDisputeCollection = confirmDisputeCollection;

                $scope.messageDriver = messageDriver;

                $log.info('In booking progress controller init -> booking: %o', $scope.booking);

            }());

            function getDriverEta() {
                var driverEta = $scope.booking.expectedTimeToPickup.minutes();

                if ($scope.booking.progress.estimatedTimeUntilPickup) {
                    driverEta = moment.duration($scope.booking.progress.estimatedTimeUntilPickup).minutes();
                }

                return driverEta;
            }

            function cancel(booking, reason) {
                setGpsPosition(booking);

                $scope.booking.cancellation = {
                    reasonCode: reason
                };

                bookingService.cancel($scope.booking).then(function () {
                    Notification.info({message: 'Your booking has been cancelled', delay: 5000});
                    $scope.removeBooking($scope.booking);
                }).catch(function (error) {
                    $log.error("failed to cancel booking. Error %o", error);
                });
            }

            function setGpsPosition(booking) {
                $scope.booking.gpsPosition = {
                    latitude: booking.pickupLocation.latitude,
                    longitude: booking.pickupLocation.longitude
                };
            }

            function disputeCollection() {
                setGpsPosition();
                bookingService.disputeCollection($scope.booking);
            }

            function confirmCancel(booking) {
                modalConfirm("Can you let us know why you are cancelling?").then(
                    function yes(reason) {
                        cancel(booking, reason);
                    });
            }

            function confirmDisputeCollection() {
                modalConfirm("This will notify ingogo that the driver was in error, and the booking will be removed from your dashboard. Are you sure you want to continue?").then(
                    function yes() {
                        disputeCollection();
                    });
            }

            function modalConfirm(message) {
                return $uibModal.open({
                    templateUrl: '/static/templates/booking-cancel-modal.html',
                    resolve: {
                        message: function () {
                            return message;
                        },
                        cancellationReasons: function () {
                            return $scope.booking.cancellationReasons;
                        }
                    },
                    controller: 'bookingCancelModalController as ctrl'
                }).result;
            }

            function messageDriver() {

                $scope.messageDriverDialog = $uibModal.open({
                    templateUrl: '/static/templates/booking-message-driver.html',
                    resolve: {
                        booking: function () {
                            return $scope.booking;
                        }
                    },
                    controller: 'bookingMessageDriverController'
                });
            }

        }]);
